<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <div class="lg:col-span-2 col-span-3 clearfix">
        <div class="lg:float-left flex gap-2 justify-end lg:mt-6">
          <div v-if="stockRequest.status === 'draft' || stockRequest.status === 'submitted'">
            <vs-button @click="openStockProductModal('Add New Product')" color="primary" class="py-2 px-3">{{ $t('Add more products') }}</vs-button>
          </div>
          <download-button class="whitespace-nowrap" :totalRows="totalRows"></download-button>
        </div>
        <shipblu-table
          :sst="true"
          v-model="selected"
          pagination
          :max-items="maximumItems"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :data="stockProducts"
          :tableLoader="tableLoader"
        >
          <template slot="thead">
            <shipblu-th>{{$t('ShipBlu Inventory ID')}}</shipblu-th>
            <shipblu-th>{{$t('SKU')}}</shipblu-th>
            <shipblu-th>{{$t('Product Name')}}</shipblu-th>
            <shipblu-th>{{$t('Size')}}</shipblu-th>
            <shipblu-th>{{$t('Color')}}</shipblu-th>
            <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
            <shipblu-th>{{$t('Weight')}}</shipblu-th>
            <shipblu-th>{{$t('Incoming Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Received Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Flags')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].requestID">
                {{ data[indextr].product ? data[indextr].product.id : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].sku">
                {{ data[indextr].product ? data[indextr].product.sku : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].productName">
                {{ data[indextr].product ? data[indextr].product.name : ''}}
              </shipblu-td>

              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product ? data[indextr].product.size : '' }}
              </shipblu-td>

               <shipblu-td :data="data[indextr].color">
                <div class="color-container h-6 w-3/4">
                  <div class="w-full h-full" :style="`background-color:${data[indextr].product ? data[indextr].product.color : 'black'}`"></div>
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].dimensions">
                {{ data[indextr].product ? data[indextr].product.d_length : '' }} x {{ data[indextr].product ? data[indextr].product.d_width : '' }} x {{ data[indextr].product ? data[indextr].product.d_height : '' }}
              </shipblu-td>
            
              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product ? data[indextr].product.weight : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].incomingQuantity">
                {{ data[indextr].product ? data[indextr].incoming_quantity : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].receivedQuantity">
                {{ data[indextr].product ? data[indextr].received_quantity : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].flags">
                <p v-for="(item, index) in data[indextr].flags" :key="index">
                  {{ item.name }}
                </p>
              </shipblu-td>

              <shipblu-td>
                <span @click="openStockProductModal('Edit Product', data[indextr])" 
                  :class="stockRequest.status === 'draft' || stockRequest.status === 'submitted' ? 'text-primary' : 'disabled'">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="h-6 w-6"
                    class="mr-2"
                  />
                </span>
                <span @click="deleteProduct(data[indextr])" 
                  :class="data[indextr].received_quantity === 0 ? 'text-danger' : 'disabled'">
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-6 w-6"
                  />
                </span>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
        <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
        <add-product-in-stock :stockProductsList="stockProducts" :stockProductModal="stockProductModal" @stockProductModal="stockProductModal = $event" @loadProductCatalog="loadProductCatalog" :type="type" :stockProduct="stockProduct"></add-product-in-stock>
      </div>
      <div class="lg:col-span-1 col-span-3">
        <shipblu-card class="lg:mt-5 p-5" :cardLoading="stockRequestLoading">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('Stock Request Info')}}</p>
          <div v-if="!editable">
            <span class="text-darkgray text-lg">{{$t('Fulfillment Center')}}:</span>
            <span class="text-primary text-lg font-medium"> {{stockRequest.fulfillment_center ? stockRequest.fulfillment_center.name : ''}}</span>
          </div>
          <div v-else>
            <v-select :class="stockRequest.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
              autocomplete="nofill" label="name" name="fulfillment center" v-model="stockRequest.fulfillment_center" v-validate="'required'"
              :placeholder="$t('Fulfillment Center') + '*'" :options="fulfillmentCenters"/>
            <span class="text-danger text-sm" v-show="errors.has('fulfillment center')">{{ errors.first('fulfillment center') }}</span>
          </div>
          <div v-if="!editable" class="mt-1">
            <span class="text-darkgray text-lg">{{$t('Number Of Vehicles')}}:</span>
            <span class="text-primary text-lg font-medium"> {{stockRequest.num_vehicles}}</span>
          </div>
          <div v-else class="mt-5">
            <vs-input type="text" v-validate="'required|min_value:1'" :label-placeholder="$t('Vehicles Number') + '*'" name="number of vehicles" v-model="stockRequest.num_vehicles" :class="stockRequest.num_vehicles ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
            <span class="text-danger text-sm" v-show="errors.has('number of vehicles')">{{ errors.first('number of vehicles') }}</span>
          </div>
          <div class="flex items-center justify-end mt-4" v-if="stockRequest.status === 'draft' || stockRequest.status === 'submitted'">
            <vs-button class="ml-auto" v-if="!editable" @click="editable = true">{{$t('Edit')}}</vs-button>
            <vs-button class="ml-auto" v-if="editable" @click="updateStockRequestInfo()">{{$t('Save')}}</vs-button>
            <vs-button class="ml-4" v-if="editable" @click="cancelEdit()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
          </div>
        </shipblu-card>
        <shipblu-card class="mt-4 p-5" :cardLoading="UROsLoading">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('Linked URO')}}</p>
          <div v-for="uro in UROs" :key="uro.index">
            <span class="text-darkgray text-lg">{{$t('ID')}}:</span>
            <span class="text-primary text-lg font-medium cursor-pointer" @click="openURO(uro)"> {{uro.id}}</span>
          </div>
        </shipblu-card>
        <shipblu-card class="mt-4 p-5" :cardLoading="trackingsLoading">
          <div class="flex">
            <p class="text-blue-900 text-xl font-medium leading-tight mb-5">{{$t('Tracking')}}</p>
            <div class="flex ml-auto">
              <vs-button class="mb-4 mr-3" v-if="stockRequest.status === 'draft'" @click="addTracking('submitted')" color="success">{{$t('Ready to Send')}}</vs-button>
              <vs-button class="mb-4" v-if="stockRequest.status === 'draft' || stockRequest.status === 'submitted'" @click="updateStockRequestStatus" color="dark">{{$t('Cancel')}}</vs-button>
            </div>
          </div>
          <div v-for="(item, index) in trackings" :key="index">
            <ul class="vx-timeline h-20 w-full mb-0 xs:mb-32 sm:mb-0">
              <li>
                <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
                <div class="vx-row flex">
                  <div class="timeline-info vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                    <small class="activity-e-status block">{{$t("Status")}}</small>
                    <vs-chip
                      :color="getOrderStatusColor(item)"
                      class="break-all product-order-status">
                        {{ $t(getStatusLabel(item)) }}
                    </vs-chip>
                  </div>
                  <div class="p-0 vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                    <small>{{$t("By")}}</small>
                    <p class="font-medium lg:text-sm mr-2">{{item.by ? item.by : '' }}</p>
                  </div>
                  <div class="p-0 vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                    <small>{{$t("Time")}}</small>
                    <p class="font-medium lg:text-sm">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString() }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </shipblu-card>
      </div>
    </div>
  </div>
</template>
<script>
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import i18nData from '../../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import AddProductInStock from '../components/AddProductInStock.vue'
import ShipbluTable from '../../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../../layouts/components/ShipBluTd.vue'
import ShipbluCard from '../../../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../../../layouts/components/ShipbluPagination.vue'
import DownloadButton from '../../../fcOperationsSupervisor/components/DownloadStock.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      editable: false,
      stockProductModal: false,
      type: '',
      trackings: [],
      trackingsLoading: false,
      stockProducts: [],
      fulfillmentCenters: [],
      stockProduct: {},
      stockRequest: {},
      stockRequestLoading: false,
      deleteData: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      UROs: [],
      UROsLoading: false,
      tableLoader: false
    }
  },
  components: {
    AddProductInStock,
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination,
    DownloadButton
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadProductCatalog()
    }
  },
  methods: {
    openStockProductModal (type, data) {
      this.type = type
      if (type === 'Add New Product') {
        this.stockProduct = Object.assign({}, {})
      } else {
        this.stockProduct = data
        this.stockProduct.product.optionalLabel = `SKU: ${this.stockProduct.product.sku} . ${this.stockProduct.product.current_inventory_level} available`
      }
      this.stockProductModal = true
    },
    cancelEdit () {
      this.editable = !this.editable
      this.loadStockRequest()
    },
    updateStockRequestInfo () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const stockRequestData = {
            fulfillment_center: this.stockRequest.fulfillment_center.id,
            num_vehicles: this.stockRequest.num_vehicles
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/`, 'patch', stockRequestData, true,
            () => {
              this.cancelEdit()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    },
    deleteProduct (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadProductCatalog()
        }
      )
    },
    loadStockRequest () {
      this.stockRequestLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/`, 'get', null, true,
        (response) => {
          this.stockRequest = response.data
          this.stockRequestLoading = false
          this.loadUROs()
        }
      )
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadProductCatalog () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${query}`, 'get', null, true,
        (response) => {
          this.stockProducts = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    loadTracking () {
      this.trackingsLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/tracking/`, 'get', null, true,
        (response) => {
          this.trackings = response.data.results.filter(item => item.status !== 'received' && item.status !== 'inbounded')
          this.trackingsLoading = false
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductSearch)
    },
    loadProductSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadProductCatalog()
    },
    formatTime (time) {
      let hr = time.split(':')[0]
      const min = time.split(':')[1]
      const ampm = hr >= 12 ? i18nData[this.$i18n.locale]['pm'] : i18nData[this.$i18n.locale]['am']
      hr = hr > 12 ? hr -= 12 : hr
      return `${hr  }:${  min  }${ampm}`
    },
    getOrderStatusColor (order) {
      if (order.status === 'stored') {
        return common.getOrderStatusColor('stored')
      } else {
        return common.getOrderStatusColor(this.getStatusLabel(order))
      }
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    updateStockRequestStatus (data) {
      this.cancelledData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure you want to cancel this stock request?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.addTracking
      })
    },
    openURO (URO) {
      this.$router.push({
        name: 'merchant-unidentified-stock-request-view',
        params: {
          stockID: URO.id
        }
      })
    },
    addTracking (status) {
      const tracking = {
        stock_request: this.$route.params.stockID,
        status: status ? status : 'cancelled'
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadStockRequest()
          this.loadTracking()
        }
      )
    },
    loadUROs () {
      this.UROsLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/unidentified-stock-requests/?stock_request=${this.$route.params.stockID}`, 'get', null, true,
        (response) => {
          this.UROs = response.data.results
          this.UROsLoading = false
        }
      )
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRequest()
    this.loadProductCatalog()
    this.loadTracking()
    this.loadFulfillmentCenters()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
  pointer-events: none;
}
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
  li {
    position: relative;

    .timeline-icon {
      position: absolute;
      top: 0;
      left: -3.2rem !important;
      border-radius: 50%;
      padding: 0px;
      padding: 0.3rem !important;
      padding-bottom: 0.4rem;
    }
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
table.shipblu-table td:first-child{
  border-left: 0px solid !important;
}
</style>